<template>
    <v-container fluid  v-if="Number(params.persona_id)">
        <v-tabs grow>
            <v-tabs-slider></v-tabs-slider>
            <v-tab :href="`#tab_datos_personales`">
                Datos Personales
            </v-tab>

            <v-tab-item :value="'tab_datos_personales'">
                <Formulario :getParams="this.params"></Formulario>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
const Formulario = () => import("./components/Formulario.vue");

export default {
    name: 'ParticipantesAdministradoresPerfil',
    data() {
        return {
            loading: true,
            params: {
                persona_id: null,
                cat_tipo_persona_id: null,
            }
        }
    },
    components: {
        Formulario
    },
    methods: {
        async getInfoUsuario() {

            window.axios.get('/usuarios/info').then(response => {
                if (response.data.success) {

                    let data = response.data.data;
                    this.params.persona_id = data.persona_id;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo saber tu información.");
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created() {
        this.getInfoUsuario();
    }
}
</script>

<style>

</style>
